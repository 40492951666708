<template>
  <div
    class="modal modal-md fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" style="max-width: 400px" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-bold" id="exampleModalLabel">联系我们</h5>
          <a data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <feather
                type="x"
                style="display: inline-block; margin-right: 6px"
                size="20"
              />
            </span>
          </a>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 text-center">
              <a
                @click="trackEvent('telegram')"
                href="https://t.me/easygetappcom"
                target="_blank"
                class="p-3 text-center theme-contact"
                style="cursor: pointer; display: block"
              >
                <img
                  src="@/assets/img/fab-telegram.svg"
                  height="80"
                  alt="确认需求"
                />
                <p class="pt-3">Telegram</p>
              </a>
              <button
                @click="copyText('https://t.me/easygetappcom', 'telegram')"
                type="button"
                class="btn btn-primary btn-sm"
                style="padding: 8px 10px; text-transform: none !important"
              >
                <span class="d-flex">
                  <!-- <feather
                    type="copy"
                    style="display: inline-block; margin-right: 6px"
                    size="16"
                  /> -->
                  联系我们
                </span>
              </button>
            </div>
            <div class="col-lg-6 text-center">
              <a
                @click="trackEvent('whatsapp')"
                href="https://wa.me/15856080787"
                target="_blank"
                class="p-3 text-center theme-contact"
                style="cursor: pointer; display: block"
              >
                <img
                  src="@/assets/img/fab-whatsapp.svg"
                  height="80"
                  alt="确认需求"
                />
                <p class="pt-3">Whatsapp</p>
              </a>
              <button
                @click="copyText('https://wa.me/15856080787', 'whatsapp')"
                type="button"
                class="btn btn-primary btn-sm mb-4"
                style="padding: 8px 10px; text-transform: none !important"
              >
                <span class="d-flex">
                  <!-- <feather
                    type="copy"
                    style="display: inline-block; margin-right: 6px"
                    size="16"
                  /> -->
                  联系我们
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    copyText(text, title) {
      window.open(text, "_blank");
      this.trackEvent(title);
      // navigator.clipboard.writeText(text);
    },
    trackEvent(link) {
      this.$gtag.event("click_" + link, {
        value: link,
        soure: "Modal",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; /* Adjusts for spacing */
}

.modal-dialog {
  width: 100% !important;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.theme-contact {
  background: #f3f5f8;
  border-radius: 10px;
  text-align: center;
  max-width: max-content;
  margin: 0 auto !important;
  margin-bottom: 10px !important;
  padding: 10px 25px !important;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid gray;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
