<template>
  <div class="card" style="width: 18rem">
    <div class="conver-image">
      <img v-if="coverImage !== undefined" :src=coverImage />
      <img v-else :src="require(`@/assets/img/image-placeholder.svg`)" />
    </div>
    <div class="category">
      <div class="float-start">
        {{ categories }}
      </div>
      <div class="float-end">
        <span>{{ minutes }}</span>
        分钟阅读
      </div>
    </div>
    <div class="title">{{ title }}</div>
    <div class="article">{{ art }}</div>
    <div class="created-time">{{ createdTime }}</div>
    <a
      class="btn btn-primary rounded-4"
      :href="`blog/${id}`"
    >
      阅读完整文章
    </a>
  </div>
</template>

<script>

export default {
  props: [
    "id",
    "coverImage",
    "category",
    "minutes",
    "title",
    "article",
    "createdTime"
  ],
  data() {
    return {
      arti: null
    }
  },
  created() {
    if(this.article) {
      const vi = this
      var request = new XMLHttpRequest();
      request.open('GET', vi.article, true);
      request.send()
      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE) {
          const res = request.response
          const str = res.toString()
          this.arti = str.replace( /(<([^>]+)>)/ig, ' ');
        }
      }
    }
  },
  computed: {
    art () {
      return this.arti
    },
    categories () {
      return this.category
        .filter((i) => i.status !== "Hide")
        .map((i) => {
          return i.name ?? "-"
        })
        .join(" | ");
    },
  }
};
</script>


<style lang="scss" scoped>
.card {
  margin-left: 0;
  margin: auto;
  border: 0;
  max-width: 600px;
  width: 100% !important;
  .conver-image {
    img {
      width: 100%;
      height: 300px;
    }
  }
  .category {
    background: #f3f5f8;
    height: 55px;
    margin-top: 10px;
    padding: 16px;
    font-size: 16px;
    .float-start {
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .title {
    font-weight: 800;
    font-size: 19px;
    padding: 20px 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    height: 78px;
  }
  .article {
    color: #808080;
    font-size: 17px;
    padding: 20px 16px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    height: 127px;
  }
  .created-time {
    color: #808080;
    font-size: 15px;
    font-weight: 700;
    padding: 20px 16px;
  }

  .btn {
    width: 190px;
    margin-left: 16px;
  }

}

@media only screen and (max-width: 992px) {
  .card {
    margin-left: auto;
  }
}
</style>
