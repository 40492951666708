<template>
  <div class="card" style="width: 18rem">
    <div class="circle-card">
      <img :src="require(`@/assets/img/${icon}`)" alt="" :width=width />
    </div>
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <div class="easy-bar bar-theme m-0 mb-4"></div>
      <h6 class="card-subtitle mb-2">
        {{ subtitle }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle", "icon", "width"],
};
</script>

<style lang="scss" scoped>
.card {
  height: 404px;
  margin: auto;
  margin-left: 0;
  position: relative;
  padding: 35px;
  border-radius: 4%;
  border: 0;
  background: #f3f5f8;
  max-width: 600px;
  width: 100% !important;

  &:hover {
    background: white !important;
    box-shadow: 10px 10px 28px 1px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 10px 10px 28px 1px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 10px 10px 28px 1px rgba(0, 0, 0, 0.16);

    .circle-card {
      border: 10px solid #f3f5f8 !important;
      background: white !important;
    }
  }

  .card-body {
    padding-top: 75px;
  }

  .card-title {
    font-weight: bold !important;
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .card-subtitle {
    font-size: 20px;
    color: #0e314c !important;
    margin-top: 30px;
  }
  .circle-card {
    position: absolute;
    top: -45px;
    left: 40px;
    display: flex;
    justify-content: center;
    background: #f3f5f8;
    border-radius: 60%;
    height: 154px;
    width: 154px;
    border: 10px solid white;
  }
}

@media only screen and (max-width: 992px) {
  .card {
    margin-left: auto;
  }
}
</style>
