<template>
  <BasicLayout>
    <section class="easy-main-banner-section">
      <div class="container-xxl">
        <div class="easy-main-banner">
          <div class="easy-banner-content col-lg-6">
            <h1>一站式APP解决方案服务</h1>
            <p>
              苹果签名 + APP上架 + 分发下载 + 免签H5封装 + APP渠道统计 +
              开发者账号
            </p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
          <div class="easy-banner-image col-lg-6">
            <img src="@/assets/img/main-header-image.svg" alt="" width="500" />
          </div>
        </div>
      </div>
    </section>

    <section class="our-service-section">
      <div class="container-xxl">
        <div class="our-service-title">
          <h3 class="text-bold">四大特色</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row gy-6">
          <div class="col-lg-4">
            <CardDetails
              title="APP 签名"
              subtitle="为客户提供稳定的企业签名、超级签名、TF签名以及APP分发。"
              icon="home-signature-card-1.svg"
              width="80"
            ></CardDetails>
          </div>
          <div class="col-lg-4">
            <CardDetails
              title="APP上架"
              subtitle="提供APP上架过审应用商店、技术支持、解决版本被拒等各种疑难杂症。"
              icon="home-signature-card-2.svg"
              width="100"
            ></CardDetails>
          </div>
          <div class="col-lg-4">
            <CardDetails
              title="APP渠道统计"
              subtitle="全渠道统计一站式服务，同时支持H5渠道统计、移动广告效果检测与ASA归因统计。"
              icon="home-signature-card-3.svg"
              width="80"
            ></CardDetails>
          </div>
          <div class="col-lg-4">
            <CardDetails
              title="开发者账号"
              subtitle="传统的注册账号至少需要六步甚至更多步骤，EasyGetApp基于多年的互联网资源，帮您轻松获取到iOS或安卓账号，免去注册、审核等所有步骤。"
              icon="home-signature-card-4.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-4">
            <CardDetails
              title="封装打包"
              subtitle="网页封装原生编程技术，开发网站快速定制封装APP，完美兼容99%以上的网站，完美支持安卓和苹果双系统。"
              icon="home-signature-card-5.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-4">
            <CardDetails
              title="分发下载"
              subtitle="极速上传应用，极速应用内测分发，安卓苹果自动识别，合并应用自动识别设备，自动下载对应版本。"
              icon="home-signature-card-6.svg"
              width="100"
            ></CardDetails>
          </div>
        </div>
      </div>
    </section>

    <section class="network-info-section">
      <div class="container-xxl">
        <div class="network-info">
          <div class="network-info-image">
            <img src="@/assets/img/network.svg" alt="" />
          </div>
          <div class="network-info-desc">
            <p>
              丰富互联网信息化项目服务经验，让我们对项目实施的规范化、<br />标准化有更多的理解。
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="our-service-2-section">
      <div class="container-xxl">
        <div class="our-service-2-title">
          <h3 class="text-bold">我们的服务</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="our-service-2-content">
          <div class="our-service-2-content-cart">
            <div class="icon">
              <img src="@/assets/img/regular.svg" alt="" width="70" />
            </div>
            <div class="description">
              <p class="title">正规公司</p>
              <p class="details">
                行业第一批最多独家独立证书的签名商，充足证书保驾护航，给您最优质的保障服务。
              </p>
            </div>
          </div>
          <div class="our-service-2-content-cart">
            <div class="icon">
              <img
                src="@/assets/img/develop-team.svg"
                alt="专业的开发团队"
                width="80"
              />
            </div>
            <div class="description">
              <p class="title">专业的开发团队</p>
              <p class="details">
                拥有多年的安卓和苹果开发经验，提供免费技术咨询，排查解答技术疑问。
              </p>
            </div>
          </div>
          <div class="our-service-2-content-cart">
            <div class="icon">
              <img
                src="@/assets/img/high-cost.svg"
                alt="高性价比多选择"
                width="90"
              />
            </div>
            <div class="description">
              <p class="title">高性价比多选择</p>
              <p class="details">
                根据用户需求，提供不同套餐，更新免费，为用户提供最经济实惠的服务。
              </p>
            </div>
          </div>
          <div class="our-service-2-content-cart">
            <div class="icon">
              <img src="@/assets/img/security.svg" alt="安全稳定" width="90" />
            </div>
            <div class="description">
              <p class="title">安全稳定</p>
              <p class="details">
                公司拥有独立的账号签名，并且严格把控下载数量以及APP类型，保证100%安全和稳定。
              </p>
            </div>
          </div>
          <div class="our-service-2-content-cart">
            <div class="icon">
              <img
                src="@/assets/img/distribution-speed.svg"
                alt="分发速度"
                width="60"
              />
            </div>
            <div class="description">
              <p class="title">分发速度</p>
              <p class="details">
                极速上架，及时下载、专业性高、性价比高，解决APP上线问题。
              </p>
            </div>
          </div>
          <div class="our-service-2-content-cart">
            <div class="icon">
              <img
                src="@/assets/img/24-hour.svg"
                alt="7*24小时客户服务"
                width="70"
              />
            </div>
            <div class="description">
              <p class="title">7*24小时客户服务</p>
              <p class="details">
                我们将提供24小时全天候专业服务，随时为用户提供服务。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="qa-section process-section web">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">签名服务流程</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="developer-acc-signature signature-service-steps">
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-1.svg" alt="沟通需求" />
            </div>
            <div class="step-1-desc step-title">
              <p>沟通需求</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/home-acc-process-2.svg"
                alt="确认选择服务"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>确认选择服务</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-3.svg" alt="订单付款" />
            </div>
            <div class="step-1-desc step-title">
              <p>订单付款</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/home-acc-process-4.svg"
                alt="提供IPA文件"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>提供IPA文件</p>
            </div>
          </div>
        </div>
        <div
          class="developer-acc-signature signature-service-steps"
          style="height: 86px; margin-bottom: 0px"
        >
          <div class="signature-step visible-hidden">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-1.svg" alt="沟通需求" />
            </div>
            <div class="step-1-desc step-title">
              <p>沟通需求</p>
            </div>
          </div>
          <div class="signature-arrow visible-hidden">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step visible-hidden">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/home-acc-process-2.svg"
                alt="确认选择服务"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>确认选择服务</p>
            </div>
          </div>
          <div class="signature-arrow visible-hidden">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step visible-hidden">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-3.svg" alt="订单付款" />
            </div>
            <div class="step-1-desc step-title">
              <p>订单付款</p>
            </div>
          </div>
          <div class="signature-arrow visible-hidden">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon" style="background: none">
              <img src="@/assets/img/arrow-down.svg" alt="提供IPA文件" />
            </div>
            <div class="step-1-desc step-title visible-hidden">
              <p>提供IPA文件</p>
            </div>
          </div>
        </div>
        <div class="developer-acc-signature signature-service-steps">
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-5.svg" alt="合作完成" />
            </div>
            <div class="step-1-desc step-title">
              <p>合作完成</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-6.svg" alt="测试验收" />
            </div>
            <div class="step-1-desc step-title">
              <p>测试验收</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-7.svg" alt="回发客户" />
            </div>
            <div class="step-1-desc step-title">
              <p>回发客户</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-8.svg" alt="签名文件" />
            </div>
            <div class="step-1-desc step-title">
              <p>签名文件</p>
            </div>
          </div>
        </div>
        <div class="text-center" style="font-size: 16px">
          <p>*注：以上只是简洁的流程，实际项目中流程会有更多细节，请悉知</p>
        </div>
      </div>
    </section> -->

    <!-- <section class="qa-section process-section mobile">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">签名服务流程</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="developer-acc-signature signature-service-steps">
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-1.svg" alt="沟通需求" />
            </div>
            <div class="step-1-desc step-title">
              <p>沟通需求</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/home-acc-process-2.svg"
                alt="确认选择服务"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>确认选择服务</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-3.svg" alt="订单付款" />
            </div>
            <div class="step-1-desc step-title">
              <p>订单付款</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/home-acc-process-4.svg"
                alt="提供IPA文件"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>提供IPA文件</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-5.svg" alt="合作完成" />
            </div>
            <div class="step-1-desc step-title">
              <p>合作完成</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-6.svg" alt="测试验收" />
            </div>
            <div class="step-1-desc step-title">
              <p>测试验收</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-7.svg" alt="回发客户" />
            </div>
            <div class="step-1-desc step-title">
              <p>回发客户</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>&lt;</p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/home-acc-process-8.svg" alt="签名文件" />
            </div>
            <div class="step-1-desc step-title">
              <p>签名文件</p>
            </div>
          </div>
        </div>

        <div class="text-center" style="font-size: 16px">
          <p>*注：以上只是简洁的流程，实际项目中流程会有更多细节，请悉知</p>
        </div>
      </div>
    </section> -->

    <section class="contact-us-section">
      <div class="container-xxl">
        <div class="contact-us">
          <div class="contact-us-image">
            <img src="@/assets/img/achieve-golas.svg" alt="" />
          </div>
          <div class="contact-us-desc">
            <p>EasyGetApp 期待与您相遇现在让我们开始为您的企业实现需求目标。</p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即联系
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="qa-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">常见问题</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="qa-content">
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-1"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-1"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">01</span>
                  <span>Q: 需要提供APP的源码?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-1">
              <div class="qa-content-answer bg-white border-bottom">
                <span>A: 不需要源码，只发IPA格式的安装包即可。</span>
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-2"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-2"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">02</span>
                  <span>Q: 签名需要多长时间?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-2">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 上传IPA安装包后开始签名，一般来说10分钟内完成签名。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-3"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-3"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">03</span>
                  <span>Q: 签名的APP可以在App Store搜索到吗?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-3">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  不能，只能用下载链接或者扫描二维码进行下载。如果要在应用市场搜索并下载，需要注册对应应用市场账号，进行APP提交。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-4"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-4"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">04</span>
                  <span>Q: 客户怎么安装签名好的软件?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-4">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  安装包签名完成之后，我们会通知您并且把签名好的安装文件发给您，您测试以后即可进行分发安装。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-5"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-5"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">05</span>
                  <span>Q: 会不会中途掉包？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-5">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  掉包闪退就是证书被封停，这个情况是可能出现的。一般情况下，当一张证书签名应用过多、或下载量过大时（或其他某些不确定的原因），可能会导致苹果官方封禁证书，进而就会导致
                  App 无法安装，且已经安装的 App
                  无法正常打开。虽然无法保证证书不被封停，但我们利用一些策略会尽量保证证书更加稳定。同时，针对可能出现掉包的情况，我们会在您购买服务的有效期内提供免费重签服务。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <FabV2 />

    <ModalContact />
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import CardDetails from "@/components/CardDetails";
import FabV2 from "@/components/FabV2";
import ModalContact from "@/components/ModalContact";

export default {
  components: {
    BasicLayout,
    CardDetails,
    FabV2,
    ModalContact,
  },
};
</script>

<style lang="scss">
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .our-service-2-content-cart .icon {
    height: 85px;
  }
}
</style>
