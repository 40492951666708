import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/pages/index.vue";
import SuperSignature from "@/pages/super-signature.vue";
import BusinessSignature from "@/pages/business-signature.vue";
import TfSignature from "@/pages/tf-signature.vue";
import AppUpload from "@/pages/app-upload.vue";
import AppDistribution from "@/pages/app-distribution.vue";
import AppChannelStatistics from "@/pages/app-channel-statistics.vue";
import DeveloperAccount from "@/pages/developer-acc";
import Packaging from "@/pages/packaging";
import Blog from "@/pages/blog";
import ViewBlog from "@/pages/view-blog";
import PageNotFound from "@/pages/404";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/super-signature",
    component: SuperSignature,
    meta: {
      title: "超级签名",
    },
  },
  {
    path: "/business-signature",
    component: BusinessSignature,
    meta: {
      title: "企业签名",
    },
  },
  {
    path: "/tf-signature",
    component: TfSignature,
    meta: {
      title: "TF签名",
    },
  },
  {
    path: "/app-upload",
    component: AppUpload,
    meta: {
      title: "APP上架",
    },
  },
  {
    path: "/app-distribution",
    component: AppDistribution,
    meta: {
      title: "APP分发",
    },
  },
  {
    path: "/app-channel-statistics",
    component: AppChannelStatistics,
    meta: {
      title: "APP渠道统计",
    },
  },
  {
    path: "/developer-acc",
    component: DeveloperAccount,
    meta: {
      title: "IOS开发者账号",
    },
  },
  {
    path: "/packaging",
    component: Packaging,
    meta: {
      title: "H5封包APP",
    },
  },
  {
    path: "/blog",
    component: Blog,
    meta: {
      title: "博客",
    },
  },
  {
    path: "/blog/:id",
    component: ViewBlog,
    meta: {
      title: "博客",
    },
  },
  { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  // mode: "history", - removed due build route error
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = "Easygetapp | " + title;
  }
  // Continue resolving the route
  next();
});

export default router;
