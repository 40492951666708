<template>
  <div @click="$emit('view')">
    <div class="row">
      <div class="col-lg-6">
        <img
          v-if="coverImg"
          :src="coverImg"
          alt=""
          height="460px"
          width="100%"
          style="margin: 20px 0; border-radius: 20px"
        />
        <img
          v-else
          :src="require(`@/assets/img/image-placeholder.svg`)"
          alt=""
          height="460px"
          width="100%"
          style="margin: 20px 0; border-radius: 20px"
        />
      </div>
      <div class="col-lg-6">
        <div class="card carousel-details">
          <div class="card-body d-flex flex-column">
            <p
              style="
                background-color: #00b1ff;
                color: white;
                max-width: max-content;
                padding: 5px 20px;
                border-radius: 5px;
                font-size: 22px;
              "
            >
              精选文章
            </p>
            <h3 style="font-weight: bolder !important; font-size: 48px">
              {{ limitStrLength(title, 36) }}
            </h3>
            <p style="font-size: 18px; color: gray">
              {{ limitStrLength(content, 310) }}
            </p>
            <div class="blog-footer mt-auto" style="font-size: 22px">
              <div style="font-weight: bolder">
                {{ categoryList }}
              </div>
              <div style="margin-left: auto; min-width: 120px">
                {{ readingTime ? readingTime : 0 }}分钟阅读
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coverImg: String,
    title: String,
    article: String,
    category: Array,
    readingTime: Number,
  },
  data() {
    return {
      content: "",
      categoryList: [],
    };
  },
  created() {
    this.categoryList = this.category
      .filter((i) => i.status !== "Hide")
      .map((i) => {
        return i.name ?? "-";
      })
      .join(" | ");

    if (this.article) {
      const vi = this;
      var request = new XMLHttpRequest();
      request.open("GET", vi.article, true);
      request.send();
      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE) {
          const res = request.response;
          const str = res.toString();
          this.content = str.replace(/(<([^>]+)>)/gi, " ");
        }
      };
    }
  },
  methods: {
    limitStrLength(text, max_length) {
      const data = this.removeTags(text);
      if (data.length > max_length - 3) {
        return data.substring(0, max_length).trimEnd() + "...";
      } else {
        return data;
      }
    },
    removeTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>
<style>
.carousel-details {
  height: 460px !important;
  margin: 20px 0;
  box-shadow: 0px 4px 10px 0px #00000040;
  margin-right: 10px;
  border-radius: 2%;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .carousel-details {
    min-height: 600px !important;
    height: auto !important;
    margin-right: 0;
  }
}

.blog-footer {
  display: flex;
}
@media only screen and (max-width: 600px) {
  .blog-footer {
    display: block !important;
  }
}
</style>
