<template>
  <div class="blog-search-container">
    <img
      :src="require(`@/assets/img/blog-featured-bg.svg`)"
      class="blog-search-bg"
    />
    <div class="card-blog-search">
      <div class="card-body-blog">
        <p class="blog-search-title">搜索文章</p>
        <v-select
          @search="onSearch"
          @close="onClose"
          @option:selecting="onSelect"
          :clearSearchOnBlur="() => {}"
          :filterable="false"
          v-model="selectedBlog"
          :options="options"
          placeholder="输入文章名称"
          class="blog-search"
        >
          <template v-slot:option="option">
            <div class="blog-options">
              <p>{{ option.label }}</p>
              <p class="blog-category">{{ option.readingTime }}分钟阅读</p>
            </div>
          </template>
        </v-select>
        <button
          type="button"
          @click="$emit('onSearch', searchInput)"
          class="btn btn-primary blog-search-btn"
        >
          搜索
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import escape from "lodash/escape";
export default {
  data() {
    return {
      options: [],
      selectedBlog: "",
      searchInput: "",
    };
  },
  created() {
    this.fetchBlogs();
  },
  methods: {
    onSelect(data) {
      this.redirectBlog(data?.id);
    },
    onClose: debounce(function () {
      this.selectedBlog?.id ? "" : this.fetchBlogs(false, "");
    }, 100),
    redirectBlog(id) {
      id ? this.$router.push({ path: `blog/${id}` }) : "";
    },
    onSearch(search, loading) {
      this.searchInput = escape(search);
      loading(true);
      this.fetchBlogs(loading, search);
    },
    fetchBlogs: debounce(async function (loading, search) {
      const {
        data: { data },
      } = await this.$store.dispatch("api/searchBlog", {
        keyword: escape(search),
      });
      this.options = data?.map((i) => {
        return {
          id: i.id,
          label: i.title,
          readingTime: i.readingTime ? i.readingTime : "-",
        };
      });
      if (loading) loading(false);
    }, 700),
  },
};
</script>

<style lang="scss">
.blog-search-container {
  position: relative;
  .blog-search-bg {
    position: absolute !important;
    z-index: 1 !important;
    left: -95px;
    top: 45px;
    height: 230px;
  }
}
.card-blog-search {
  position: relative;
  z-index: 2 !important;
  width: 100% !important;
  padding: 60px 100px;
  background: #f8f9fd;
  box-shadow: 0px 4px 40px 0px #00000026;
}

.blog-search-title {
  margin: 0 !important;
  margin-right: 10px;
  min-width: 150px;
  font-weight: bolder;
  font-size: 32px;
}

.blog-search {
  width: 100%;
  .vs__dropdown-toggle {
    height: 40px !important;
  }
  .vs__search::placeholder {
    color: #d2d2d2 !important;
  }
  .vs__search {
    opacity: 1 !important;
  }
}
.blog-search-btn {
  margin-left: 20px;
  max-width: 140px;
  width: 100%;
  height: 40px;
  padding: 0 12px !important;
  border-radius: 10px;
}
.blog-options {
  display: flex;
  width: 100%;
  p {
    margin: 0 !important;
  }
  .blog-category {
    margin-left: auto !important;
    color: #bebebe !important;
  }
}

.card-body-blog {
  align-items: center;
  display: flex;
}
@media only screen and (max-width: 880px) {
  .card-blog-search {
    padding: 40px;
  }
  .card-body-blog {
    display: block !important;
    .blog-search-title {
      margin-bottom: 5px !important;
    }
    .blog-search-btn {
      margin: 0;
      margin-top: 10px;
    }
  }
}
</style>
