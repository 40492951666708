<template>
  <div class="fab-container">
    <a
      @click="trackEvent('telegram')"
      href="https://t.me/easygetappcom"
      target="_blank"
    >
      <div class="d-flex flex-column align-items-center pt-2">
        <img src="@/assets/img/fab-telegram.svg" alt="" width="50" />
        <p class="m-0 mt-2">Telegram</p>
      </div>
    </a>

    <a
      @click="trackEvent('whatsapp')"
      href="https://wa.me/15856080787"
      target="_blank"
      style="margin-top: 20px"
    >
      <div class="d-flex flex-column align-items-center pt-2">
        <img src="@/assets/img/fab-whatsapp.svg" alt="" width="50" />
        <p class="m-0 mt-2">Whatsapp</p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: ["title", "icon", "top", "link"],
  methods: {
    trackEvent(link) {
      this.$gtag.event("click_" + link, {
        value: link,
        soure: "FAB",
      });
    },
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 600px) {
  .fab-container {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    top: unset !important;
    bottom: 150px !important;
    a {
      width: 80px !important;
      height: 70px !important;
      font-size: 12px !important;
      img {
        height: 30px !important;
      }
    }
  }
}

.fab-container {
  z-index: 10;
  position: fixed;
  top: 40vh;
  right: 20px;
  a {
    display: block !important;
    width: 100px;
    height: 100px;
    background: #f3f5f8;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
  }
  &:hover {
    color: #00b1ff !important;
  }
}
</style>
