import request from "@/utils/request";

const api = {
  categories: "/dropdown/public/categories",
  blogPosts: "content/public/search",
  featuredBlogs: "content/public/search",
  searchBlog: "dropdown/public/search",
  get: (id) => `/content/public/detail/${id}`,
};

export function categories() {
  return request({
    url: api.categories,
    method: "get",
  });
}

export function blogPosts(data) {
  return request({
    url: api.blogPosts,
    method: "post",
    data,
  });
}

export function featuredBlogs(data) {
  return request({
    url: api.featuredBlogs,
    method: "post",
    data,
  });
}

export function searchBlog(data) {
  return request({
    url: api.searchBlog,
    method: "post",
    data,
  });
}

export function viewBlog(id) {
  return request({
    url: api.get(id),
    method: "get",
  });
}
