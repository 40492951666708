<template>
  <BasicLayout>
    <section class="app-upload-main-header-section">
      <div class="container-xxl">
        <div class="app-upload-main-header">
          <div class="app-upload-main-header-content col-lg-6">
            <h1>APP代上架 (IOS/安卓)</h1>
            <p>
              提供上架APP商店技术支持方案，解决版本被拒各种疑难杂症、省时省事，多年研发工程师经验，快速上架保驾护航。
            </p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
          <div class="app-upload-main-header-image col-lg-6">
            <img src="@/assets/img/app-upload-rocket.svg" alt="" width="450" />
          </div>
        </div>
      </div>
    </section>
    <section class="characteristics-section">
      <div class="container-xxl">
        <div class="characteristics-title">
          <h3 class="text-bold">四大特色</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row gy-6">
          <div class="col-lg-6">
            <CardDetails
              title="APP预审"
              subtitle="上架前做预审，资深上架研发提供审核帮助，仔细评估APP功能，找出问题，提高过审效率！"
              icon="app-upload-card-1.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="苹果iOS版本审核专家"
              subtitle="自研代码混淆技术工具，采用工具 +人工双重深度混淆代码方式，减少APP修改工作量，降低混淆后可能导致的bug问题，轻松过审。"
              icon="app-upload-card-2.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="APP代上架"
              subtitle="用户提供APP相关信息及资料，如应用介绍、图标ICON、应用截图、关键词等。"
              icon="app-upload-card-3.svg"
              width="70"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="APP定制"
              subtitle="专业定制软件，以客户需求为中心，个性化量身定制具有行业竞争力产品！"
              icon="app-upload-card-4.svg"
              width="100"
            ></CardDetails>
          </div>
        </div>
      </div>
    </section>
    <section class="apple-android-upload-section">
      <div class="container-xxl">
        <div class="row gy-5">
          <div class="col-lg-4">
            <div class="appUploadDetails d-flex flex-column">
              <img src="@/assets/img/app-upload-img-1.svg" alt="确认需求" />
              <h3 style="font-weight: bold; margin-top: 20px">苹果上架</h3>
              <div class="easy-bar bar-theme m-0 mt-3 mb-3"></div>
              <p style="font-size: 18px">
                iOS
                应用在提交AppStore的48小时之内，就可以得到官方的审核结果。极大的节省等待审核时间，让您的APP与用户更快相遇。
              </p>
              <!-- <button
                class="btn btn-primary rounded-4 w-max mt-auto"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                立即咨询
              </button> -->
            </div>
          </div>
          <div class="col-lg-4">
            <div class="appUploadDetails d-flex flex-column">
              <img src="@/assets/img/app-upload-img-2.svg" alt="确认需求" />
              <h3 style="font-weight: bold; margin-top: 20px">马甲包开发</h3>
              <div class="easy-bar bar-theme m-0 mt-3 mb-3"></div>
              <p style="width: 80%; font-size: 18px">
                精通各类马甲包制作，让上架的包更加稳定，轻松过审，不掉包。
              </p>
              <!-- <button
                class="btn btn-primary rounded-4 w-max mt-auto"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                立即咨询
              </button> -->
            </div>
          </div>
          <div class="col-lg-4">
            <div class="appUploadDetails d-flex flex-column">
              <img src="@/assets/img/app-upload-img-3.svg" alt="确认需求" />
              <h3 style="font-weight: bold; margin-top: 20px">安卓上架</h3>
              <div class="easy-bar bar-theme m-0 mt-3 mb-3"></div>
              <p style="font-size: 18px">
                安卓应用24小时内得到审核结果。可以提交Google
                Play、华为、小米等各大应用市场。
              </p>
              <!-- <button
                class="btn btn-primary rounded-4 w-max mt-auto"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                立即咨询
              </button> -->
            </div>
          </div>

          <div class="col-lg-12 text-center">
            <button
              class="btn btn-primary rounded-4 w-max mt-auto"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="qa-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">我们的服务</h3>
          <div class="easy-bar bar-theme"></div>
        </div>

        <div class="row gy-4 mx-auto">
          <div class="col-lg-4">
            <CardPicture
              title="苹果AppStore上架"
              icon="app-upload-service-1.svg"
            ></CardPicture>
          </div>
          <div class="col-lg-4">
            <CardPicture
              title="谷歌PlayStore上架"
              icon="app-upload-service-2.svg"
              size="100"
            ></CardPicture>
          </div>
          <div class="col-lg-4">
            <CardPicture
              title="苹果开发者账号"
              icon="app-upload-service-3.svg"
            ></CardPicture>
          </div>
          <div class="col-lg-4">
            <CardPicture
              title="谷歌开发者账号"
              icon="app-upload-service-4.svg"
            ></CardPicture>
          </div>
          <div class="col-lg-4">
            <CardPicture
              title="马甲包开发定制"
              icon="app-upload-service-5.svg"
              size="100"
            ></CardPicture>
          </div>
          <div class="col-lg-4">
            <CardPicture
              title="App预审"
              icon="app-upload-service-6.svg"
            ></CardPicture>
          </div>
        </div>
      </div>
    </section>

    <section class="qa-section process-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">合作流程</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="developer-acc-signature signature-service-steps">
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/app-upload-process-1.svg" alt="确认需求" />
            </div>
            <div class="step-1-desc step-title">
              <p>联系客服</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/app-upload-process-2.svg" alt="签署合同" />
            </div>
            <div class="step-1-desc step-title">
              <p>提交信息</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/app-upload-process-3.svg" alt="支付款项" width="70" />
            </div>
            <div class="step-1-desc step-title">
              <p>开发定制</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/app-upload-process-4.svg" alt="交接账号" />
            </div>
            <div class="step-1-desc step-title">
              <p>提交审核</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/app-upload-process-5.svg" alt="购买成功" />
            </div>
            <div class="step-1-desc step-title">
              <p>上架完成</p>
            </div>
          </div>
        </div>
        <div class="text-center" style="font-size: 16px">
          <p>*注：以上只是简洁的流程，实际项目中流程会有更多细节，请悉知。</p>
        </div>
      </div>
    </section>

    <section class="qa-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">常见问题</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="qa-content">
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-1"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-1"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">01</span>
                  <span>Q: 如何通过EasyGetApp上传苹果应用商店？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-1">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  客户只需要提供已注册好的苹果开发者账号，及应用名称、关键词、图标、介绍等资料即可。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-2"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-2"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">02</span>
                  <span>Q: 没有开发者账号能代理上传吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-2">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  可以。我们可以协助注册应用商场开发者账号，也可以直接通过EasyGetApp购买应用商场开发
                  者账号，然后再提交到开发者后台。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-3"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-3"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">03</span>
                  <span>Q: 代理上传和普通的上传有什么区别？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-3">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  代理上传主要解决对苹果上架流程不熟悉的困惑，通过EasyGetApp多年的分发经验，可谓此类开发者提供专业的辅导，节省时间成本。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-4"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-4"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">04</span>
                  <span>Q: 通过EasyGetApp能保证上架成功吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-4">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  我们有全面专业的团队协助开发和预审，并保证上架成功。若上架不成功，可全额退款。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-5"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-5"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">05</span>
                  <span>Q: 支持哪些APP上架平台？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-5">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: iOS: AppStore、安卓: Google
                  Play、华为、小米等各大应用市场。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FabV2 />

    <ModalContact />
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import CardDetails from "@/components/CardDetails";
import CardPicture from "@/components/CardPicture";
import ModalContact from "@/components/ModalContact";

import FabV2 from "@/components/FabV2";
export default {
  components: {
    BasicLayout,
    CardDetails,
    CardPicture,
    FabV2,
    ModalContact,
  },
};
</script>

<style>
.process-icon {
  background: #f3f5f8;
  border-radius: 50%;
  width: 125px;
  height: 125px;
  display: inline-block;
  margin-bottom: 10px;
}
.process-icon img {
  height: 60px;
  margin-top: 30px;
}

.step-title {
  font-size: 16px !important;
}
</style>
