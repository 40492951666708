<template>
  <div class="card d-flex align-items-center p-5" style="width: 18rem">
    <img
      :src="require(`@/assets/img/${icon}`)"
      alt=""
      :width="size ? size : 80"
    />
    <div class="card-body mt-3">
      <h4 class="card-title">{{ title }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "icon", "size"],
};
</script>

<style scoped>
.card {
  height: 100%;
  margin: auto !important;
  border: 0;
  border-radius: 5%;
  background: white;
  box-shadow: 10px 10px 28px 1px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 10px 10px 28px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 10px 10px 28px 1px rgba(0, 0, 0, 0.16);
  max-width: 410px !important;
  width: 100% !important;
}
</style>
