<template>
  <BasicLayout>
    <section class="business-signature-main-header-section">
      <div class="container-xxl">
        <div class="business-signature-main-header">
          <div class="business-signature-main-header-content col-lg-6">
            <h1>企业签名</h1>
            <p>
              我们为您提供苹果官方的iOS企业证书签名服务，让您的App无需提交苹果AppStore即可在手机、平板上直接安装，适用于所有iOS应用。
              免越狱、无限制安装、安全稳定。
            </p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
          <div class="business-signature-main-header-image col-lg-6">
            <img
              src="@/assets/img/business-signature-main-image.svg"
              alt=""
              width="500"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="characteristics-section">
      <div class="container-xxl">
        <div class="characteristics-title">
          <h3 class="text-bold">四大特色</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row gy-6">
          <div class="col-lg-6">
            <CardDetails
              title="苹果官方企业证书"
              subtitle="专业的资深团队，为您提供苹果官方颁发的企业证书签名服务。"
              icon="business-signature-card-1.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="一对一客户服务"
              subtitle="无论在售前还是售后，专人一对一随时解答您提出的任何问题。"
              icon="business-signature-card-2.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="高性价比多选择"
              subtitle="提供多种质高价优的月签选择，针对不同分发量匹配不同版本。"
              icon="business-signature-card-3.svg"
              width="100"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="有效期内免费重签"
              subtitle="我们承诺有效期内掉签均可免费重签，及时为您处理相关问题。"
              icon="business-signature-card-4.svg"
              width="90"
            ></CardDetails>
          </div>
        </div>
      </div>
    </section>

    <section class="online-sign-info-section">
      <div class="container-xxl">
        <div class="online-sign-info">
          <div class="online-sign-info-desc">
            <h3 class="text-bold">超级签名</h3>
            <div class="easy-bar bar-theme m-0 mt-4 mb-3 center-sm"></div>
            <ul>
              <li>
                快速在线签名，全自动签名IPA同时支持企业证书&个人证书UDID签名。
              </li>
              <li>各种插件、游戏应用，一步搞定。</li>
            </ul>
          </div>
          <div class="online-sign-info-image">
            <img src="@/assets/img/sign-image-2.svg" alt="" />
          </div>
        </div>
      </div>
    </section>

    <section class="qa-section process-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">签名服务流程</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="developer-acc-signature signature-service-steps">
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/business-acc-process-1.svg"
                alt="选择签名服务，提交IPA软件包"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>选择签名服务，提交IPA包</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/business-acc-process-2.svg"
                alt="确认需求，并进行付款"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>确认需求，并进行付款</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/business-acc-process-3.svg"
                alt="技术人员签好IPA包并测试"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>技术人员签好IPA包并测试</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/business-acc-process-4.svg"
                alt="用户下载签名包，交易完成"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>用户下载签名包，交易完成</p>
            </div>
          </div>
        </div>
        <div class="text-center" style="font-size: 16px">
          <p>*注：以上只是简洁的流程，实际项目中流程会有更多细节，请悉知。</p>
        </div>
      </div>
    </section>

    <section class="qa-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">常见问题</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="qa-content">
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-1"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-1"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">01</span>
                  <span>Q: 企业签名需要提供APP的源码?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-1">
              <div class="qa-content-answer bg-white border-bottom">
                <span>A: 不需要源码，只发IPA格式的安装包即可。</span>
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-2"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-2"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">02</span>
                  <span>Q: 签名需要多长时间?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-2">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 一般来说 10
                  分钟内签名好，如其他特殊情况，客服会告知您完成时间。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-3"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-3"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">03</span>
                  <span>Q: 签名的APP可以在AppStore搜索到吗?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-3">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 不能。企业签名后的 APP
                  可以直接发给用户安装，无需越狱，无需苹果账号，无需苹果审核。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-4"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-4"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">04</span>
                  <span>Q: 签好后，如何给自己的用户去安装APP?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-4">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  安装包签名完成之后，可生成下载链接和二维码，发给用户，即可安装。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-5"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-5"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">05</span>
                  <span>Q: 企业签名是否支持推送通知？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-5">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  企业签名默认不支持推送通知，如果有特殊需要，请联系客服选择特色配套。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-6"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-6"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">06</span>
                  <span>Q: 你们出售企业账号吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-6">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  对于部分要求较高的用户，我们目前也准备了已经申请好的苹果企业账号出售，如有需要，请联系客服。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-7"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-7"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">07</span>
                  <span>Q: 会不会中途掉签？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-7">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  可能存在，我们将通过给APP分类签名和独立证书签名等方式，尽量规避该风险。如果掉签，免费补签。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-8"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-8"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">08</span>
                  <span>Q: 企业签名的APP有下载数量限制吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-8">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  无限制任意安装。可以使用提供的免费下载次数，如果需要更多下载次数，请购买。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <FabV2 />

    <ModalContact />
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import CardDetails from "@/components/CardDetails";
import FabV2 from "@/components/FabV2";
import ModalContact from "@/components/ModalContact";

export default {
  components: {
    BasicLayout,
    CardDetails,
    FabV2,
    ModalContact,
  },
};
</script>
