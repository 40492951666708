<template>
  <BasicLayout>
    <section class="app-packaging-main-header-section">
      <div class="container-xxl">
        <div class="app-packaging-main-header">
          <div class="app-packaging-main-header-content col-lg-6">
            <h1>APP封装打包</h1>
            <p>
              只需一个H5网站地址，就可以创建自己的APP。纯手工打包，杜绝代码重复和植入。
            </p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
          <div class="app-packaging-main-header-image col-lg-6">
            <img
              src="@/assets/img/app-packaging-main-image.svg"
              alt=""
              width="600"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="characteristics-section">
      <div class="container-xxl">
        <div class="characteristics-title">
          <h3 class="text-bold">四大特色</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row gy-6">
          <div class="col-lg-6">
            <CardDetails
              title="纯手工打包"
              subtitle="iOS和安卓开发人员手工打包、杜绝APP报病毒。"
              icon="packaging-card-1.svg"
              width="130"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="无代码植入"
              subtitle="不会植入任何控制代码和时间控制。"
              icon="packaging-card-2.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="无时间限制"
              subtitle="一次打包终身使用，没有使用时间限制。"
              icon="packaging-card-3.svg"
              width="120"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="功能定制"
              subtitle="由于是手工打包，可以定制定各种功能，如：支付宝微信支付、视频播放、APP导航等等。"
              icon="packaging-card-4.svg"
              width="90"
            ></CardDetails>
          </div>
        </div>
      </div>
    </section>
    <section class="packaging-section">
      <div class="container-xxl">
        <div class="signature-service-title">
          <h3 class="text-bold">封装步骤</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row packaging-steps">
          <div class="packaging-step col-lg-4">
            <div class="step-number">
              <div class="number">1</div>
            </div>
            <div class="step-icon">
              <img src="@/assets/img/packacking-process-1.svg" alt="" />
            </div>
            <div class="step-1-desc">
              <p style="text-align: left">
                联系客服并自由搭配所需的功能。
              </p>
            </div>
          </div>
          <div class="packaging-step col-lg-4">
            <div class="step-number">
              <div class="number">2</div>
            </div>
            <div class="step-icon">
              <img src="@/assets/img/packacking-process-2.svg" alt=""/>
            </div>
            <div class="step-1-desc">
              <p>由技术人员手工打包。</p>
            </div>
          </div>
          <div class="packaging-step col-lg-4">
            <div class="step-number">
              <div class="number">3</div>
            </div>
            <div class="step-icon">
              <img src="@/assets/img/packacking-process-3.svg" alt="" />
            </div>
            <div class="step-1-desc">
              <p>对iOS版本封装的APP进行签名后分发；安卓可以直接进行分发。</p>
            </div>
          </div>
        </div>
        <div class="packaging-hint">
          <p>*注：以上只是简洁的流程，实际项目中流程会有更多细节，请悉知。</p>
        </div>
      </div>
    </section>
    <section class="qa-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">常见问题</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="qa-content">
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-1"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-1"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">01</span>
                  <span>Q:  APP封装打包可以上架AppStore吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-1">
              <div class="qa-content-answer bg-white border-bottom">
                <span>
                  A: APP封装打包是可以上架AppStore的，EasyGetApp也提供APP上架服务，提供全程一站式服务。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-2"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-2"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">02</span>
                  <span>Q: APP封装打包应用可以上架安卓市场吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-2">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 可以。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-3"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-3"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">03</span>
                  <span>Q: 打包封装APP是什么？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-3">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 打包封装app的原理主要是对UI、对视窗、对界面上进行了简易处理，使得APP的视觉体验上可以跟上时代潮流，使用前沿的UI解决方案来替代原生APP的UI处理，使得开发APP更加的简易。通过打包封装APP可以快速开发APP，降低研发成本，减少常规项目不必要的技术支出，从而实现低成本开发APP的目的。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-4"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-4"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">04</span>
                  <span
                    >Q:
                    APP封装打包优势在哪里？网页混合开发APP和原生APP区别是什么？</span
                  >
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-4">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 网页打包APP的优势主要在于快捷方便，极大降低开发成本，缩短开发时间，减少大量重复造轮子的工作。无论是混合开发还是原生开发APP，APP的底层框架都是原生的，都是根据苹果公司，谷歌公司的标准搭建的，如果不按照IOS和安卓的开发准则，APP都无法安装成功的。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FabV2 />

    <ModalContact />
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import CardDetails from "@/components/CardDetails";
import FabV2 from "@/components/FabV2";
import ModalContact from "@/components/ModalContact";

export default {
  components: {
    BasicLayout,
    CardDetails,
    FabV2,
    ModalContact,
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 480px) {
  .packaging-step {
    min-width: 250px !important;
    max-width: 250px !important;
  }

  .packaging-step div p {
    font-size: 16px !important;
    min-width: 0 !important;
  }
}
</style>
