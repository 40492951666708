import { categories, blogPosts, featuredBlogs, searchBlog, viewBlog } from "@/api/api";

export default {
  namespaced: true,
  actions: {
    async getCategories() {
      return await categories();
    },
    async getBlogPosts(_, payload) {
      return await blogPosts(payload);
    },
    async getFeaturedBlogs(_, payload) {
      return await featuredBlogs(payload);
    },
    async searchBlog(_, payload) {
      return await searchBlog(payload);
    },
    async viewBlog (_, id) {
      return await viewBlog(id)
    },
  },
};
