<template>
  <BasicLayout>
    <section class="tf-signature-main-header-section">
      <div class="container-xxl">
        <div class="tf-signature-main-header">
          <div class="tf-signature-main-header-content col-lg-5">
            <h1>TestFlight 签名</h1>
            <p>
              苹果官方认可的测试分发，全新的分发模式，零风险分发无忧，稳定性更好、体验性好、更新消息苹果官方渠道推送。
            </p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
          <div class="tf-signature-main-header-image col-lg-6">
            <img src="@/assets/img/tf-img-1.svg"  />
          </div>
        </div>
      </div>
    </section>
    <section class="characteristics-section">
      <div class="container-xxl">
        <div class="characteristics-title">
          <h3 class="text-bold">四大特色</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row gy-6">
          <div class="col-lg-6">
            <CardDetails
              title="稳定高效"
              subtitle="TF签名的应用安装成功后不会掉签，有效避免因为不稳定掉签导致的用户流失所造成的巨大损失，从源头减少推广带来的用户损失！"
              icon="tf-img-card-1.svg"
              width="110"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="性价比高"
              subtitle="专享独立账号，稳定最强！自家源头系统，无需信任，永不掉签，同一个设备可免费重复下载，无后顾之忧。"
              icon="tf-img-card-2.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="方便快捷"
              subtitle="任意苹果设备应用内可打开， 无链接屏蔽风险， 无分发限制少。更新消息苹果官方渠道推送， 下载无需点击信任。"
              icon="tf-img-card-3.svg"
              width="70"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="售后无忧，24h服务"
              subtitle="TF上架不通过全额退款，无需担心，免费提交预审，封装签名，提高上架成功率！"
              icon="tf-img-card-4.svg"
              width="90"
            ></CardDetails>
          </div>
        </div>
      </div>
    </section>
    <section class="experience-info-section">
      <div class="container-xxl">
        <div class="experience-info">
          <div class="experience-info-image col-lg-7 col-md-8 text-center">
            <img src="@/assets/img/tf-img-2.svg" alt="" />
          </div>
          <div class="experience-info-desc col-lg-5 col-md-4">
            <h3 class="text-bold">高质量体验</h3>
            <div
              class="easy-bar bar-theme m-0 mt-4 mb-3 center-sm"
             
            ></div>
            <ul>
              <li>特有机制、安全稳定</li>
              <li>稳定高并发下载</li>
              <li>精准售后服务</li>
              <li>极速安装体验</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="qa-section process-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">签名服务流程</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="developer-acc-signature signature-service-steps">
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/tf-img-step-1.svg" alt="确认需求" />
            </div>
            <div class="step-1-desc step-title">
              <p>选提交IPA包</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/tf-img-step-2.svg" alt="签署合同" />
            </div>
            <div class="step-1-desc step-title">
              <p>出具预审报告</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/tf-img-step-3.svg" alt="支付款项" />
            </div>
            <div class="step-1-desc step-title">
              <p>修改解决报告</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/tf-img-step-4.svg" alt="交接账号" />
            </div>
            <div class="step-1-desc step-title">
              <p>等待审核</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img src="@/assets/img/tf-img-step-5.svg" alt="购买成功" />
            </div>
            <div class="step-1-desc step-title">
              <p>发布出链</p>
            </div>
          </div>
        </div>
        <div class="text-center" style="font-size: 16px">
          <p>*注：以上只是简洁的流程，实际项目中流程会有更多细节，请悉知。</p>
        </div>
      </div>
    </section>
    <section class="qa-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">常见问题</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="qa-content">
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-1"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-1"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">01</span>
                  <span>Q: TF签名需要提供APP的源码?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-1">
              <div class="qa-content-answer">
                <span
                  >A:
                  需要符合IOS开发应用的规范，需提供正确打包的IPA包即可。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-2"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-2"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">02</span>
                  <span>Q: TF审核提交正确的包之后多久可以使用?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-2">
              <div class="qa-content-answer">
                <span
                  >A:
                  审核通过后，即可正常使用。特殊原因除外，可以联系我们的技术或者客服。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-3"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-3"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">03</span>
                  <span>Q: 制作TF签会影响APP后续上架吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-3">
              <div class="qa-content-answer">
                <span>
                  A:
                  不会，TF签是苹果官方认可的测试分发模式，安全稳定零风险。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-4"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-4"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">04</span>
                  <span>Q: TF签名过程中需要注意哪些问题？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-4">
              <div class="qa-content-answer">
                <span
                  >A: 制作TF签名会导致原应用的Bundle
                  id（包名）改变，如果有功能会受到影响需要提前告知客服或技术人员。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-5"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-5"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">05</span>
                  <span>Q: TF链接安装有效期为多长时间？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-5">
              <div class="qa-content-answer">
                <span>A: 因TF签是苹果官方分发链接，为三个月使用有效期。</span>
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-6"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-6"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">06</span>
                  <span>Q: TF签和超级签，企业签有什么区别？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-6">
              <div class="qa-content-answer">
                <span
                  >A:
                  TF签是一种全新的分发模式，有着超级签的稳定性，企业签的性价比，即使掉签，用户安装了在有效期内还是可以打开使用。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-7"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-7"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">07</span>
                  <span>Q: TF链接下载次数限制为多少？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-7">
              <div class="qa-content-answer">
                <span
                  >A:
                  每个安装包正常情况下载次数为一万次，如需超额的下载次数，可联系我们的客服咨询。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FabV2 />
    <ModalContact />
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import CardDetails from "@/components/CardDetails";
import FabV2 from "@/components/FabV2";
import ModalContact from "@/components/ModalContact";

export default {
  components: {
    BasicLayout,
    CardDetails,
    FabV2,
    ModalContact,
  },
};
</script>
