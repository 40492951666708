<template>
  <BasicLayout>
    <section style="overflow: hidden">
      <div class="container-xxl">
        <div class="app-blog-post-top-section">
          <div class="d-flex justify-content-start">
            <a
              class="back-button"
              href="/blog"
            >
              <img :src="require(`@/assets/img/arrow-left.svg`)" />
            </a>
          </div>
          <div class="blog-title">
            <h3 class="text-bold"> {{ blog?.title }}</h3>
            <div class="easy-bar bar-theme"></div>
          </div>
          <img :src="require(`@/assets/img/blog-featured-bg.svg`)" class="blog-top-bg">
          <div class="cover-image">
            <img v-if="blog?.coverImage !== null" :src=blog?.coverImage.fileLink />
            <img v-else :src="require(`@/assets/img/image-placeholder.svg`)" />
          </div>
        </div>
        <div class="app-blog-post-bottom-section">
          <div class="article">
            <div v-html="article"></div>
          </div>
        </div>
        <img :src="require(`@/assets/img/blog-featured-bg.svg`)" class="blog-bottom-bg">
      </div>
    </section>    
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";

export default {
  components: {
    BasicLayout
  },
  data() {
    return {
      blog: null,
      article: null
    }
  },
  async created() {
    const blog = await this.$store.dispatch("api/viewBlog", this.$route.params.id);
    this.blog = blog.data

    const vi = this
    var request = new XMLHttpRequest();
    request.open('GET', vi.blog?.article?.fileLink, true);
    request.send()
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        vi.article = request.response
      }
    }
  }
};
</script>

<style lang="scss">
.app-blog-post-top-section {
  position: relative;
  margin-top: 50px;

  .back-button {
    background: white !important;
    border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 50%;
    z-index: 1;
  }

  .blog-title {
    text-align: center;
    position: relative;
    top: -50px;

    hr {
      font-size: 2.5rem;
    }
  }

  .blog-top-bg {
    position: absolute;
    right: -194px !important;
    top: -70px !important;
    height: 260px;
  }

  .cover-image {
    margin-top: -30px;

    img {
      position: relative;
      width: 100%;
      height: 798px;
      border-radius: 20px;
    }
  }
}

.app-blog-post-bottom-section {
  position: relative;
  margin-top: 50px;

  .article {
    margin-bottom: 50px;
    min-height: 100px;
    line-break: anywhere;
    position: relative;
    z-index: 2;
  }
}

.blog-bottom-bg {
  position: relative;
  left: -230px;
  height: 260px;
  z-index: 1;
}

@media only screen and (max-width: 992px) {
  .app-blog-post-top-section .blog-title {
    top: 0;
    line-break: anywhere;
  }

  .app-blog-post-top-section .cover-image {
    margin-top: 0;
  }

  .app-blog-post-top-section .cover-image img {
    height: unset;
  }
}
</style>
