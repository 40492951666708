<template>
  <BasicLayout>
    <section class="super-signature-main-header-section">
      <div class="container-xxl">
        <div class="super-signature-main-header">
          <div class="super-signature-main-header-content col-lg-6">
            <h1>超级签名</h1>
            <p>
              iOS 应用新分发模式，使用苹果开发者个人账号实现的 iOS
              自动化签名，告别企业签名频繁掉签烦恼，帮您稳定提升客户留存。
            </p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
          <div class="super-signature-main-header-image col-lg-6">
            <img
              src="@/assets/img/easyGetApp-signature.svg"
              alt=""
              width="550"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="characteristics-section">
      <div class="container-xxl">
        <div class="characteristics-title">
          <h3 class="text-bold">四大特色</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row gy-6 test">
          <div class="col-lg-6">
            <CardDetails
              title="安装即用"
              subtitle="无需越狱，扫码直接下载。安装即用，无需提供账号、设备信息。"
              icon="super-signature-card-1.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="稳定高效"
              subtitle="告别企业签名频繁掉签的苦恼，无需越狱、无需开发者账号、全程自动化，操作简单。"
              icon="super-signature-card-2.svg"
              width="110"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="方便快捷"
              subtitle="立即分发，分发只需提供应用安装包，无需提交商店，随时更新，一键立即开启。"
              icon="super-signature-card-3.svg"
              width="70"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="性价比高"
              subtitle="按需购买，未使用设备不过期，同设备多次下载，或下载多款应用，只收费一次。"
              icon="super-signature-card-4.svg"
              width="90"
            ></CardDetails>
          </div>
        </div>
      </div>
    </section>

    <section class="super-sign-info-section">
      <div class="container-xxl">
        <div class="super-sign-info">
          <div class="super-sign-info-desc col-lg-7 col-md-6">
            <h3 class="text-bold">超级签名</h3>
            <div class="easy-bar bar-theme m-0 mb-3 center-sm"></div>
            <p>
              超级签名是基于苹果个人开发者账号提供的签名服务, 通过描述文件的形式来添加苹果设备的udid来实现签名, 超级签名使用了苹果提供给开发者们的Ad-Hoc分发通道, 把安装设备当作开发设备进行分发, 超级签名实际上也是一种专门为不能够上架App Store的应用做的一种分发方式。
            </p>
          </div>
          <div class="super-sign-info-image col-lg-5 col-md-5">
            <img src="@/assets/img/sign-image.svg" alt="" width="400" />
          </div>
        </div>
      </div>
    </section>

    <section class="qa-section process-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">签名服务流程</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="developer-acc-signature signature-service-steps">
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/developer-acc-process-1.svg"
                alt="选择签名服务，提交IPA软件包"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>选择签名服务，提交IPA包</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/developer-acc-process-2.svg"
                alt="确认需求，并进行付款"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>确认需求，并进行付款</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/developer-acc-process-3.svg"
                alt="技术人员签好IPA包并测试"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>技术人员签好IPA包并测试</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/developer-acc-process-4.svg"
                alt="用户下载签名包，交易完成"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>用户下载签名包，交易完成</p>
            </div>
          </div>
        </div>
        <div class="text-center" style="font-size: 16px">
          <p>*注：以上只是简洁的流程，实际项目中流程会有更多细节，请悉知。</p>
        </div>
      </div>
    </section>

    <section class="qa-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">常见问题</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="qa-content">
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-1"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-1"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">01</span>
                  <span>Q: 超级签名需要提供APP的源码?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-1">
              <div class="qa-content-answer bg-white border-bottom">
                <span>A: 不需要源码，只发IPA格式的安装包即可。</span>
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-2"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-2"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">02</span>
                  <span>Q: 签名需要多长时间?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-2">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 上传IPA安装包后开始签名，一般来说10分钟内完成签名。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-3"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-3"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">03</span>
                  <span>Q: 签名的APP可以在AppStore搜索到吗?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-3">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  不能，超级签名后的APP可以直接将连接发给客户安装，无需越狱，无需苹果账号，无需苹果审核。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-4"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-4"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">04</span>
                  <span>Q: 如何在手机上安装APP？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-4">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 复制下载地址，到手机浏览器，打开页面，进行安装, 或用手机扫描二维码进行安装。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-5"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-5"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">05</span>
                  <span>Q: 为什么有些APP提示无法安装？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-5">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  苹果APP刚封装好或者打包好，没添加个人证书/企业证书，没法安装。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-6"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-6"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">06</span>
                  <span
                    >Q:
                    如果同一台设备安装应用后卸载，再重新安装，计费时算一台设备还是两台？</span
                  >
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-6">
              <div class="qa-content-answer bg-white border-bottom">
                <span>A: 按照真实设备数量计算，只计算一台设备。</span>
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-7"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-7"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">07</span>
                  <span>Q: EasyGetApp签名版本稳定性如何？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-7">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  超级签名版本在理论上来说稳定性是远远高于企业版内测分发的模式的，被封的概率极低。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-8"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-8"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">08</span>
                  <span>Q: 什么是设备数，跟下载次数有什么区别吗?</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-8">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  设备数指的是能够下载您这个应用的设备数量最大值，多次购买是叠加的。
                  不同于下载次数，一个设备下载多次或者多个应用，只收费一个设备的费用。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FabV2 />
    <ModalContact />
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import CardDetails from "@/components/CardDetails";
import ModalContact from "@/components/ModalContact";
import FabV2 from "@/components/FabV2";

export default {
  components: {
    BasicLayout,
    CardDetails,
    FabV2,
    ModalContact,
  },
};
</script>
