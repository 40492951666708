<template>
  <BasicLayout>
    <section class="app-blog-main-header-section" style="overflow: hidden">
      <div class="container-xxl carousel-margin">
        <CarouselBlog></CarouselBlog>
      </div>
      <div style="background: #f3f5f8">
        <div class="container-xxl search-blog">
          <SearchBlog @onSearch="onSearch"></SearchBlog>
        </div>
      </div>
      <div class="container-xxl">
        <div class="app-blog-post-section">
          <ul class="nav nav-pills" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-selected="true"
                @click="changeCategory('')"
              >
                全部
              </button>
            </li>
            <li
              v-for="c in categories"
              :key="c.id"
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-selected="false"
                @click="changeCategory(c.id)"
              >
                {{ c.name }}
              </button>
            </li>
          </ul>
          <div class="tab-content">
            <div class="row gy-4">
              <div v-for="b in blogPosts" :key="b.id" class="col-lg-4">
                <CardBlogDetails
                  :id="b?.id"
                  :coverImage="b?.coverImage?.fileLink"
                  :category="b?.categories"
                  :minutes="b?.readingTime"
                  :title="b?.title"
                  :article="b?.article?.fileLink"
                  :createdTime="b?.createdTime"
                />
              </div>
            </div>
          </div>
          <pagination
            v-model="page"
            :per-page="6"
            :records="total"
            @paginate="changePagination"
          />
        </div>
      </div>
    </section>
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import CardBlogDetails from "@/components/CardBlogDetails";
import CarouselBlog from "@/components/CarouselBlog";
import SearchBlog from "@/components/SearchBlog";

export default {
  components: {
    BasicLayout,
    CardBlogDetails,
    CarouselBlog,
    SearchBlog,
  },
  data() {
    return {
      categories: null,
      blogPosts: null,
      page: 1,
      size: 6,
      total: 0,
      keyword: "",
      categoryId: ""
    };
  },
  async created() {
    const categories = await this.$store.dispatch("api/getCategories");
    this.categories = categories.data;
    this.changeBlogPosts();
  },
  methods: {
    onSearch(keyword) {
      this.keyword = keyword;
      this.categoryId = '';
      this.changeBlogPosts();
    },
    async changeCategory(val) {
      this.categoryId = val;
      this.changeBlogPosts();
    },
    changePagination() {
      this.changeBlogPosts();
    },
    async changeBlogPosts() {
      const data = {
        page: this.page - 1,
        size: this.size,
        status: "Publish",
        title: this.title,
        keyword: this.keyword,
        categoryId: this.categoryId,
      };
      const response = await this.$store.dispatch("api/getBlogPosts", data);
      this.total = response.data.totalElements;
      this.blogPosts = response.data.data;
    },
  },
};
</script>

<style lang="scss">
.app-blog-post-section {
  margin-top: 100px;

  .nav-pills {
    color: #181818;
    font-size: 16px;
    padding: 15px 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);

    .nav-link {
      color: #181818;
      line-break: anywhere;
      text-align: left;
    }

    .nav-link.active {
      color: #00b1ff;
      background-color: #fff;
      font-weight: 900;
    }
  }

  .tab-content {
    margin-top: 30px;
  }

  .pagination {
    margin-top: 70px;
    display: flex !important;
    justify-content: center;

    .page-link {
      color: #808080;
      margin: 0 10px;
      border-radius: 6px;
      width: 40px;
      text-align: center;
    }

    .page-link.active {
      color: #00b1ff;
      background-color: #fff;
      border-color: #00b1ff;
    }
  }

  .VuePagination__pagination-item-prev-chunk,
  .VuePagination__pagination-item-next-chunk,
  .VuePagination__count {
    display: none;
  }
}

.carousel-margin {
  margin-top: 100px;
}

.search-blog {
  padding: 100px 10px !important;
  margin-top: 30px;
}

@media only screen and (max-width: 480px) {
  .carousel-margin {
    margin-top: -20px !important;
  }
}
</style>
