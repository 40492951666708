<template>
  <body>
    <header id="header" class="easy-header">
      <div class="easy-responsive-nav">
        <div class="container">
          <div class="easy-responsive-menu">
            <div class="logo">
              <a href="/">
                <img src="@/assets/img/superApp-logo.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="easy-header-nav">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <a class="navbar-brand" href="/"
              ><img
                src="@/assets/img/EasyGetApp-Logo.svg"
                alt="logo"
                width="180"
            /></a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <router-link class="nav-link" aria-current="page" to="/"
                    >首页</router-link
                  >
                </li>
                <li class="nav-item">
                  <div class="dropdown">
                    <button class="dropbtn" ref="route0">
                      苹果签
                      <!-- <feather type="chevron-down" size="12"></feather> -->
                      <img
                        src="@/assets/img/ico-chev-down.svg"
                        id="chevDefault"
                        style="margin-left: 4px"
                        height="5"
                        alt=""
                      />
                      <img
                        src="@/assets/img/ico-chev-down-active.svg"
                        id="chevActive"
                        style="margin-left: 4px"
                        alt=""
                        height="6"
                      />
                    </button>
                    <div class="dropdown-content">
                      <router-link
                        class="dropdown-nav-link"
                        to="/super-signature"
                        >超级签名</router-link
                      >
                      <div class="routeDivider"></div>
                      <router-link
                        class="dropdown-nav-link"
                        to="business-signature"
                        >企业签名</router-link
                      >
                      <div class="routeDivider"></div>
                      <router-link class="dropdown-nav-link" to="/tf-signature"
                        >TF签名</router-link
                      >
                    </div>
                  </div>
                </li>
                <li class="nav-item">
                  <div class="dropdown">
                    <button class="dropbtn" ref="route1">
                      APP上架
                      <img
                        src="@/assets/img/ico-chev-down.svg"
                        id="chevDefault"
                        style="margin-left: 4px"
                        alt=""
                        height="5"
                      />
                      <img
                        src="@/assets/img/ico-chev-down-active.svg"
                        id="chevActive"
                        style="margin-left: 4px"
                        alt=""
                        height="6"
                      />
                    </button>
                    <div class="dropdown-content">
                      <router-link class="dropdown-nav-link" to="/app-upload"
                        >Google Play上架</router-link
                      >
                      <div class="routeDivider"></div>
                      <router-link class="dropdown-nav-link" to="/app-upload"
                        >App Store上架</router-link
                      >
                    </div>
                  </div>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/app-distribution"
                    >APP分发</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/app-channel-statistics"
                    >APP渠道统计</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/developer-acc"
                    >IOS开发者账号</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/packaging"
                    >H5封包APP</router-link
                  >
                </li>
                <li class="nav-item" ref="route2">
                  <router-link class="nav-link" to="/blog">博客</router-link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
    <section style="margin-top: 5.5rem">
      <slot />
    </section>
    <div class="copyright-area">
      <p>Copyright © 2023 EasyGetApp All Rights Reserved</p>
    </div>
  </body>
</template>

<script>
export default {
  mounted() {
    var route = this.$route.path;
    var routeList = [
      ["/super-signature", "/business-signature", "/tf-signature"],
      ["/app-upload"],
    ];
    if (routeList[0].includes(route)) {
      this.$refs.route0.classList.add("routerParentActive");
    } else if (routeList[1].includes(route)) {
      this.$refs.route1.classList.add("routerParentActive");
    } else if (route.slice(0, 5) === "/blog") {
      this.$refs.route2.classList.add("routerBlogActive");
    } else {
      this.clearActive();
    }
  },
  methods: {
    clearActive() {
      this.$refs.route0.classList.remove("routerParentActive");
      this.$refs.route1.classList.remove("routerParentActive");
      this.$refs.route2.classList.remove("routerBlogActive");
    },
  },
};
</script>

<style lang="scss" scoped>
.easy-header {
  a,
  .dropbtn {
    font-weight: 400 !important;
  }
}
#chevActive {
  display: none;
}

.routerParentActive {
  color: #0085f2 !important;

  #chevActive {
    display: inline;
  }
  #chevDefault {
    display: none;
  }
}
.router-link-exact-active {
  color: #0085f2 !important;
}
.routeDivider {
  border-bottom: 1px solid #cccccc;
  margin-left: 10px;
  margin-right: 10px;
}
.routerBlogActive {
  a {
    color: #0085f2 !important;
  }
}
</style>
