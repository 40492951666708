import jQuery from "jquery";

(function ($) {
  "use strict";
  $(window).on("scroll", function () {
    $(this).scrollTop() > 120
      ? $("#header").addClass("is-sticky")
      : $("#header").removeClass("is-sticky");
  });
})(jQuery);
