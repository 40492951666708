<template>
  <BasicLayout>
    <section class="app-upload-main-header-section">
      <div class="container-xxl">
        <div class="app-upload-main-header">
          <div class="app-upload-main-header-content col-lg-6">
            <h1>EasyGetApp渠道统计</h1>
            <p>H5渠道统计与APP广告平台效果监测，全面统计线上线下渠道效果。</p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
          <div class="app-upload-main-header-image col-lg-6">
            <img src="@/assets/img/app-img-1.svg" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="characteristics-section">
      <div class="container-xxl">
        <div class="characteristics-title">
          <h3 class="text-bold">作弊监控</h3>
          <div class="easy-bar bar-theme"></div>
          <h4 style="max-width: 900px; margin: 0 auto; line-height: 35px">
            EasyGetApp渠道统计为广告主提供高效的作弊监控防护，识别作弊IP/设备，对虚假流量进行主动清洗过滤，为您实时拦截和标记统计异常流量。
          </h4>
        </div>
        <div class="row gy-6">
          <div class="col-lg-6">
            <CardDetails
              title="图表分析"
              subtitle="MTTI趋势分析与点击劫持分析，判断分析存在点击劫持行为风险。"
              icon="app-img-card-1.svg"
              width="80"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="异常统计"
              subtitle="实时监控作弊IP/设备，时间、来源渠道数，安装次数、来源渠道数等统计。"
              icon="app-img-card-2.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="数据报表"
              subtitle="实时监控H5渠道、广告平台渠道、ASA渠道的异常安装等数据。"
              icon="app-img-card-3.svg"
              width="80"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="规则配置"
              subtitle="监控疑似作弊刷量的IP和设备，用户可根据自身业务设置合适的阈值。"
              icon="app-img-card-4.svg"
              width="90"
            ></CardDetails>
          </div>
        </div>
      </div>
    </section>
    <section class="app-install-section">
      <div class="container-xxl">
        <div class="app-install">
          <div class="app-install-content col-lg-6 col-md-6 col-sm-12">
            <h3>APP传参安装</h3>
            <div class="easy-bar bar-theme" style="margin-bottom: 20px !important; margin-left: 0 !important; max-width: unset !important;"></div>
            <p>
              EasyGetApp将H5落地页链接的参数传递到安装的App中，精准识别APP安装来源，支持二维码、网页、短信、海报、Email、App拉新等各种场景。获取参数后根据自身需求做绑定关联处理，从而实现免填邀请码安装、分享效果统计、场景还原等功能。
            </p>
          </div>
          <div class="app-install-image col-lg-6 col-md-6 col-sm-12">
            <img src="@/assets/img/app-img-2.svg" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="h5-statistics-section">
      <div class="container-xxl">
        <div class="h5-statistics">
          <div class="h5-statistics-image col-lg-6 col-md-6 col-sm-12">
            <img src="@/assets/img/app-img-3.svg" alt="" />
          </div>
          <div class="h5-statistics-content col-lg-6 col-md-6 col-sm-12">
            <h3>H5渠道统计</h3>
            <div class="easy-bar bar-theme" style="margin-bottom: 20px !important; margin-left: 0 !important; max-width: unset !important;"></div>
            <ul>
              <li>支持社交分享、二维码、网站、海报、Email、短信等H5渠道投放</li>
              <li>可程序化创建的渠道链接(二维码)进行海量渠道统计</li>
              <li>独创实时报表，实时排重，实时衡量H5渠道用户价值与推广成本</li>
              <li>
                支持创建H5超级渠道并统计归属的子渠道，可灵活自由发展下级代理
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="mobile-monitor-section">
      <div class="container-xxl">
        <div class="mobile-monitor">
          <div class="mobile-monitor-content col-lg-6 col-md-6 col-sm-12">
            <h3>移动广告效果监测</h3>
            <div class="easy-bar bar-theme" style="margin-bottom: 20px !important; margin-left: 0 !important; max-width: unset !important;"></div>
            <ul>
              <li>
                为广告平台反馈安装激活量，实时监测广告效果，以优化投放策略。
              </li>
              <li>
                独立的第三方移动广告效果监测平台，为广告主提供可靠的渠道数据依据。
              </li>
            </ul>
          </div>
          <div class="mobile-monitor-image col-lg-6 col-md-6 col-sm-12" style="display: flex !important; justify-content: center !important;">
            <img
              src="@/assets/img/app-img-4.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <section class="asa-section">
      <div class="container-xxl">
        <div class="asa">
          <div class="asa-image col-lg-6">
            <img src="@/assets/img/app-img-5.svg" alt="" />
          </div>
          <div class="asa-content col-lg-6">
            <h3>ASA(苹果竞价搜索广告)归因统计</h3>
            <div class="easy-bar bar-theme" style="margin-bottom: 20px !important; margin-left: 0 !important; max-width: unset !important;"></div>
            <ul>
              <li>
                支持H5渠道统计、移动广告平台统计、ASA归因一站式评估APP全渠道统计
              </li>
              <li>
                多维度报表，提供APP内的安装激活、活跃、留存、付费等数据指标
              </li>
              <li>ASA归因数据辅助投放，帮助广告主效果追踪与投放策略优化</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <FabV2 />

    <ModalContact />
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import CardDetails from "@/components/CardDetails";
import ModalContact from "@/components/ModalContact";

import FabV2 from "@/components/FabV2";
export default {
  components: {
    BasicLayout,
    CardDetails,
    FabV2,
    ModalContact,
  },
};
</script>
