<template>
  <BasicLayout>
    <section class="devloper-acc-main-header-section">
      <div class="container-xxl">
        <div class="devloper-acc-main-header">
          <div class="devloper-acc-main-header-content col-lg-6">
            <h1>开发者账号 (iOS/安卓)</h1>
            <p>
              我们拥有多年的交易经验、给予最安全、最可靠的方式来保证您的账号。
            </p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
          <div class="devloper-acc-main-header-image col-lg-6">
            <img
              src="@/assets/img/developer-acc-main-image-1.svg"
              alt=""
              width="500"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="characteristics-section">
      <div class="container-xxl">
        <div class="characteristics-title">
          <h3 class="text-bold">四大特色</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row gy-6">
          <div class="col-lg-6">
            <CardDetails
              title="正规公司"
              subtitle="多年的行业经验，早已成为行业内的佼交者，拥有良好的口碑，同数十家企业、公司保持良好的合作关系。"
              icon="developer-acc-card-1.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="安全保障"
              subtitle="我们接受任何您认为安全的付款方式，必要时可提供法律服务、签订合同，真正做到无忧付款。"
              icon="developer-acc-card-2.svg"
              width="110"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="专业团队"
              subtitle="我们拥有最专业的技术团队，完美解决您遇到的任何问题，为您的账号服务保驾护航。"
              icon="developer-acc-card-3.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="专业售后"
              subtitle="7*24小时在线服务，随时接收您的需求，解决您的后顾之忧。"
              icon="developer-acc-card-4.svg"
              width="100"
            ></CardDetails>
          </div>
        </div>
      </div>
    </section>
    <section class="ios-acc-section">
      <div class="container-xxl">
        <div class="ios-acc-title">
          <h3 class="text-bold">iOS账号类型</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row ios-steps">
          <div class="ios-step col-lg-4">
            <div class="step-icon">
              <img src="@/assets/img/ios-process-1.svg" alt="" />
            </div>
            <div class="ios-step-title">
              <h3 class="text-bold">海外开发者账号 (未激活)</h3>
              <div class="easy-bar bar-theme"></div>
            </div>
            <div class="step-1-desc">
              <p>
                海外已付费未满14天激活期，14天后可发布应用。亚洲、欧洲优质账号，安全稳定，数量有限，详情咨询客服。
              </p>
            </div>
          </div>
          <div class="ios-step col-lg-4">
            <div class="step-icon">
              <img src="@/assets/img/ios-process-2.svg" alt="" />
            </div>
            <div class="ios-step-title">
              <h3 class="text-bold">海外开发者账号 (已激活)</h3>
              <div class="easy-bar bar-theme"></div>
            </div>
            <div class="step-1-desc">
              <p>
                海外已满14天激活期，可直接发布应用。亚洲、欧洲优质账号，安全稳定，现买现用，详情咨询客服。
              </p>
            </div>
          </div>
          <div class="ios-step col-lg-4">
            <div class="step-icon">
              <img src="@/assets/img/ios-process-3.svg" alt="" />
            </div>
            <div class="ios-step-title">
              <h3 class="text-bold pb-2">国内开发者账号</h3>
              <div class="easy-bar bar-theme mt-5"></div>
            </div>
            <div class="step-1-desc">
              <p>
                国内已激活版，可直接发布应用及测试，现买现用，详情咨询客服。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="qa-section process-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">交易流程</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="developer-acc-signature signature-service-steps">
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/developer-acc-process-1.svg"
                alt="确认需求"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>确认需求</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather type="chevron-down" class="text-theme" size="35" />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/developer-acc-process-2.svg"
                alt="签署合同"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>签署合同</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/developer-acc-process-3.svg"
                alt="支付款项"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>支付款项</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/developer-acc-process-4.svg"
                alt="交接账号"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>交接账号</p>
            </div>
          </div>
          <div class="signature-arrow">
            <div class="arrow text-theme">
              <p>></p>
            </div>
            <div class="signature-arrow-down">
              <feather
                type="chevron-down"
                class="text-theme"
                style="display: inline-block"
                size="35"
              />
            </div>
          </div>
          <div class="signature-step">
            <div class="step-icon process-icon">
              <img
                src="@/assets/img/developer-acc-process-5.svg"
                alt="购买成功"
              />
            </div>
            <div class="step-1-desc step-title">
              <p>购买成功</p>
            </div>
          </div>
        </div>
        <div class="text-center" style="font-size: 16px">
          <p>*注：以上只是简洁的流程，实际项目中流程会有更多细节，请悉知。</p>
        </div>
      </div>
    </section>

    <section class="qa-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">常见问题</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="qa-content">
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-1"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-1"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">01</span>
                  <span>Q: 开发者账号价格很高？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-1">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 目前国内苹果已关闭企业开发者账号申请通道，少数海外国家可申请，目前只有大型集团上市公司才有希望能申请下来，物以稀为贵。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-2"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-2"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">02</span>
                  <span>Q: iOS开发者账号会不会被封？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-2">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: 只要您做出与苹果规定不相符的事情，皆有被封的可能性。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FabV2 />

    <ModalContact />
  </BasicLayout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import CardDetails from "@/components/CardDetails";
import FabV2 from "@/components/FabV2";
import ModalContact from "@/components/ModalContact";

export default {
  components: {
    BasicLayout,
    CardDetails,
    FabV2,
    ModalContact,
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 480px) {
  .ios-step-title {
    width: auto !important;
    margin-left: 0 !important;
    text-align: center;
  }
}
</style>
