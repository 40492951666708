import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueFeather from "vue-feather";
import "@/assets/js/main.js";
import VueGtag from "vue-gtag";
import store from "./store/";
import { VueAxios } from "./utils/request";
import vSelect from "vue-select";
import Pagination from 'vue-pagination-2';

Vue.config.productionTip = false;
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
require("@/assets/css/style.scss");
require("@/assets/css/responsive.css");
import "vue-select/dist/vue-select.css";

Vue.use(VueFeather);
Vue.component("v-select", vSelect);
// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios);
Vue.component('pagination', Pagination);

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-BL3HNE5C3R",
      params: {
        anonymize_ip: true,
        pageTrackerScreenviewEnabled: true,
      },
    },
  },
  router
);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
