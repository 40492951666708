<template>
  <BasicLayout>
    <section class="app-distribution-main-header-section">
      <div class="container-xxl">
        <div class="app-distribution-main-header" style="gap: 3rem !important">
          <div class="app-distribution-main-header-content col-lg-6">
            <h1>APP分发</h1>
            <p style="max-width: 470px">
              一键上传APP至分发平台，生成下载链接和二维码，支持苹果安卓合并。
              CDN全球节点加速、自定义下载页面。
            </p>
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即咨询
            </button>
          </div>
          <div class="app-distribution-main-header-image col-lg-6">
            <img
              src="@/assets/img/app-distribution-main-image-1.svg"
              alt=""
              width="720"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="characteristics-section">
      <div class="container-xxl">
        <div class="characteristics-title">
          <h3 class="text-bold">四大特色</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="row gy-6">
          <div class="col-lg-6">
            <CardDetails
              title="苹果安卓二合一"
              subtitle="生成一个下载链接或者二维码，同时可供安卓、苹果用户使用，二合一，管理省心，推广便捷。"
              icon="app-distribution-card-1.svg"
              width="90"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="全网加速大小无限制"
              subtitle="支持5G大应用上传下载，不限速，不限制包的大小，全球CDN网络节点分发，下载快人一步。"
              icon="app-distribution-card-2.svg"
              width="110"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="稳定性能负载均衡"
              subtitle="能够轻松应对大流量、大用户数量的冲击，多重服务器分担流量压力，动态调节负载均衡。"
              icon="app-distribution-card-3.svg"
              width="110"
            ></CardDetails>
          </div>
          <div class="col-lg-6">
            <CardDetails
              title="数据统计清晰"
              subtitle="后台查询权限，查看数据列表、折线图，统计查询方便管理维护。"
              icon="app-distribution-card-4.svg"
              width="80"
            ></CardDetails>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="distribution-price-section">
      <div class="container-xxl">
        <div class="distribution-price-title">
          <h3 class="text-bold">分发价格</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="distribution3-price-table">
          <div class="distribution3-price-table-title mb-5">
            <h3 class="text-bold">小包分发套餐 （300M以下）</h3>
            <h5>不先下载次数</h5>
          </div>
          <div class="package-feature-table">
            <table
              class="table table-light distribution3-price-table table-theme"
            >
              <thead>
                <tr>
                  <th scope="col">套餐</th>
                  <th scope="col">价格</th>
                  <th scope="col">说明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>包月</p>
                    <p>应用托管分发</p>
                  </td>
                  <td>
                    <p>500 元</p>
                  </td>
                  <td>
                    <ul>
                      <li>默认模板使用</li>
                      <li>APP可以保存30天</li>
                      <li>下载数不受限制</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>包季</p>
                    <p>应用托管分发</p>
                  </td>
                  <td>1200 元</td>
                  <td>
                    <ul>
                      <li>CDN 加速下载</li>
                      <li>下载页无广告</li>
                      <li>下载次数不受限制</li>
                      <li>APP可以保存90天</li>
                      <li>默认模板使用</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>包年</p>
                    <p>任选4模块</p>
                  </td>
                  <td>
                    <p>3000 元</p>
                  </td>
                  <td>
                    <ul>
                      <li>CDN 加速下载</li>
                      <li>下载页无广告</li>
                      <li>下载次数不受限制</li>
                      <li>APP可以保存365天</li>
                      <li>默认模板使用</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="distribution3-price-table">
          <div class="distribution3-price-table-title mb-5">
            <h3 class="text-bold">大包分发套餐 （300M-1.5G）</h3>
            <h5>购买套餐时长后，才可上传APP</h5>
          </div>
          <div class="package-feature-table">
            <table
              class="table table-light distribution3-price-table table-theme"
            >
              <thead>
                <tr>
                  <th scope="col">套餐</th>
                  <th scope="col">价格</th>
                  <th scope="col">说明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>包月</p>
                    <p>应用托管分发</p>
                  </td>
                  <td>
                    <p>500 元</p>
                  </td>
                  <td>
                    <ul>
                      <li>默认模板使用</li>
                      <li>APP可以保存30天</li>
                      <li>下载数不受限制</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>包季</p>
                    <p>应用托管分发</p>
                  </td>
                  <td>1200 元</td>
                  <td>
                    <ul>
                      <li>CDN 加速下载</li>
                      <li>下载页无广告</li>
                      <li>下载次数不受限制</li>
                      <li>APP可以保存90天</li>
                      <li>默认模板使用</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>包年</p>
                    <p>任选4模块</p>
                  </td>
                  <td>
                    <p>4200 元</p>
                  </td>
                  <td>
                    <ul>
                      <li>CDN 加速下载</li>
                      <li>下载页无广告</li>
                      <li>下载次数不受限制</li>
                      <li>APP可以保存365天</li>
                      <li>默认模板使用</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section> -->
    <section class="app-display-section">
      <div class="container-xxl">
        <div class="app-display-title">
          <h3 class="text-bold">案列展示</h3>
          <div class="easy-bar bar-theme"></div>
          <img src="@/assets/img/app-distribution-phone.svg" alt="" />
        </div>
      </div>
    </section>
    <section class="used-scene-section">
      <div class="container-xxl">
        <div class="used-scene-header">
          <h3 class="text-bold">使用场景</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="used-scene-content-list">
          <div class="used-scene-content">
            <div class="used-scene-icon">
              <img src="@/assets/img/used-scene-icon1.png" alt="" />
            </div>
            <div class="used-scene-desc">
              <h3>地推APP统计</h3>
              <p>
                为每个地推人员自定义参数，生成二维码链接，精准统计每个人的业绩。
              </p>
            </div>
          </div>
          <div class="used-scene-content">
            <div class="used-scene-icon">
              <img src="@/assets/img/used-scene-icon2.png" alt="" />
            </div>
            <div class="used-scene-desc">
              <h3>地推APP统计</h3>
              <p>
                用户安装APP后，无需自己操作或者搜索，就可以继续查看未安装APP时的内容，流畅的体验能提高APP的留存。
              </p>
            </div>
          </div>
          <div class="used-scene-content">
            <div class="used-scene-icon">
              <img src="@/assets/img/used-scene-icon3.png" alt="" />
            </div>
            <div class="used-scene-desc">
              <h3>地推APP统计</h3>
              <p>
                通过邀请、推荐链接安装APP后，自动显示邀请、推荐人在好友列表，有助于提高新用户的活跃和留存。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="advantage-section">
      <div class="container-xxl">
        <div class="advantage-header">
          <h3 class="text-bold">优势</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="advantage-container">
          <div class="advantage-content">
            <div class="advantage-content-icon">
              <img src="@/assets/img/advantage-1.png" alt="" width="60" />
            </div>
            <div class="advantage-content-desc">
              <h3>无感知</h3>
              <p>App拉新裂变， 免填邀请码安装，用户无感知减少操作步骤</p>
            </div>
          </div>
          <div class="advantage-content">
            <div class="advantage-content-icon">
              <img src="@/assets/img/advantage-2.png" alt="" width="60" />
            </div>
            <div class="advantage-content-desc">
              <h3>自动化</h3>
              <p>免填邀请码，自动追踪安装来源，提升转化率</p>
            </div>
          </div>
          <div class="advantage-content">
            <div class="advantage-content-icon">
              <img src="@/assets/img/advantage-3.png" alt="" width="60" />
            </div>
            <div class="advantage-content-desc">
              <h3>精准</h3>
              <p>大规模商用情况下仍然保持一对一的精准邀请来源追踪'</p>
            </div>
          </div>
          <div class="advantage-content">
            <div class="advantage-content-icon">
              <img src="@/assets/img/advantage-4.png" alt="" width="60" />
            </div>
            <div class="advantage-content-desc">
              <h3>减少成本</h3>
              <p>无需开发人员再进行邀请匹配系统的开发维护，节省开发成本</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="package-section">
      <div class="container-xxl">
        <div class="package-header">
          <h3 class="text-bold">配套</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="package-content">
          <div class="package-content-desc">
            <p>传递APP安装参数,携带自定义参数安装</p>
            <p>安装参数匹配成功率95%以上</p>
          </div>
          <div class="package-content-button">
            <button
              class="btn btn-primary rounded-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              立即联系
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="qa-section">
      <div class="container-xxl">
        <div class="qa-title">
          <h3 class="text-bold">常见问题</h3>
          <div class="easy-bar bar-theme"></div>
        </div>
        <div class="qa-content">
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-1"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-1"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">01</span>
                  <span>Q: 支持安卓和苹果同一个链接下载吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-1">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  支持，上传好IPA和APK包后，自动生成一个链接，点击按钮下载或者扫描二维码即可。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-2"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-2"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">02</span>
                  <span>Q: 如何查看APP下载次数？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-2">
              <div class="qa-content-answer bg-white border-bottom">
                <span>A: 登录分发后台，便能查看下载次数。</span>
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-3"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-3"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">03</span>
                  <span>Q: 有时候iOS App不能下载，是下载链接的问题吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-3">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A: iOS
                  APP需要超级签，才可以安装。若超级签失效，则下载失败。安卓APP则不需要超级签。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-4"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-4"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">04</span>
                  <span>Q: 超过300M以上的包，有每天免费次数吗？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-4">
              <div class="qa-content-answer bg-white border-bottom">
                <span>A: 有。我们不限制次数。</span>
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-5"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-5"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">05</span>
                  <span>Q: 大包是否也有大小限制？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-5">
              <div class="qa-content-answer bg-white border-bottom">
                <span
                  >A:
                  有限制，大于300M，小于1.5G，都可以上传。超过1.5G，则不能上传。</span
                >
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-6"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-6"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">06</span>
                  <span>Q: 大包和小包，购买的配套是否可以共享？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-6">
              <div class="qa-content-answer bg-white border-bottom">
                <span>A: 不能共享，各自分开使用独立的配套。</span>
              </div>
            </div>
          </div>
          <div class="qa-content-question-card">
            <div class="qa-content-question">
              <a
                data-bs-toggle="collapse"
                href="#qa-collapse-7"
                role="button"
                aria-expanded="false"
                aria-controls="qa-collapse-7"
              >
                <div class="qa-content-question-text text-theme">
                  <span class="number">07</span>
                  <span>Q: 如何查看大包剩余的下载次数？</span>
                </div>
                <div class="qa-content-question-img">
                  <img
                    src="@/assets/img/chevron-up-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-up"
                  />
                  <img
                    src="@/assets/img/chevron-down-circle.svg"
                    alt=""
                    width="30"
                    class="arrow-down"
                  />
                </div>
              </a>
            </div>
            <div class="collapse" id="qa-collapse-7">
              <div class="qa-content-answer bg-white border-bottom">
                <span>A: 下载次数不受限制。</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FabV2 />

    <ModalContact />
  </BasicLayout>
</template>

<script>
  import BasicLayout from '@/layouts/BasicLayout.vue';
  import CardDetails from '@/components/CardDetails';
  import ModalContact from '@/components/ModalContact';

  import FabV2 from '@/components/FabV2';
  export default {
    components: {
      BasicLayout,
      CardDetails,
      FabV2,
      ModalContact,
    },
  };
</script>
