<template>
  <div style="position: relative">
    <img
      :src="require(`@/assets/img/blog-featured-bg.svg`)"
      class="featured-bg"
    />
    <VueSlickCarousel
      :arrows="false"
      ref="carousel"
      :dots="false"
      :autoplay="true"
      :autoplaySpeed="4000"
      v-if="featuredBlogs.length"
    >
      <CarouselCard
        @view="viewBlog(i.id)"
        v-for="i in featuredBlogs"
        :key="i.id"
        :coverImg="i.coverImage?.fileLink"
        :title="i.title"
        :article="i?.article?.fileLink"
        :category="i.categories"
        :readingTime="i.readingTime"
      ></CarouselCard>
    </VueSlickCarousel>
    <div class="d-flex justify-content-end"  v-if="featuredBlogs.length">
      <button
        class="carousel-button"
        @click="Prev()"
        style="margin-right: 10px"
      >
        <img :src="require(`@/assets/img/carousel-bnt-prev.svg`)" />
      </button>
      <button class="carousel-button" @click="showNext()">
        <img :src="require(`@/assets/img/carousel-bnt-next.svg`)" />
      </button>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import CarouselCard from "@/components/CarouselCard";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "MyComponent",
  components: { VueSlickCarousel, CarouselCard },
  data() {
    return {
      featuredBlogs: [],
    };
  },
  async created() {
    await this.getFeaturedBlogs();
  },
  methods: {
    viewBlog(id) {
      id ? this.$router.push({ path: `blog/${id}` }) : "";
    },
    Prev() {
      this.$refs.carousel.prev();
    },
    showNext() {
      this.$refs.carousel.next();
    },
    async getFeaturedBlogs() {
      const payload = {
        featuredPost: true,
        status: "Publish",
      };
      const {
        data: { data },
      } = await this.$store.dispatch("api/getFeaturedBlogs", payload);
      this.featuredBlogs = data;
    },
  },
};
</script>

<style>
.carousel-button {
  background: white !important;
  border: 1px solid #d9d9d9;
  padding: 10px;
}
.featured-bg {
  position: absolute;
  right: -90px !important;
  top: -80px !important;
  height: 230px;
}

@media only screen and (max-width: 480px) {
  .featured-bg {
    top: -10px !important;
  }
}
</style>
